:root {
    --brand-primary            : #820828;
    --brand-primary-lighten-10 : #b20b37;
    --brand-primary-lighten-40 : #f56188;
    --brand-primary-darken-5   : #6a0721;
    --brand-primary-darken-10  : #520519;
    --brand-primary-darken-12  : #480416;
    --brand-primary-opacity-25 : #82082840;
    --brand-primary-opacity-50 : #82082880;

    --brand-secondary          : var(--brand-primary);

    --highlight                : #fccfdb;
    --highlight-tint           : #feecf1;

    --navbar-height            : 60px;
    --navbar-brand-width       : 220px;
    --navbar-brand-height      : 60px;
    --navbar-brand-margin-left : 10px;
    --navbar-brand-logo-size   : auto;
    --navbar-brand-logo        : url('/assets/images/mazda-us-logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/mazda-us-logo.png');

    --logo-gap                 : 24px;
    --logo-bg-size             : auto;
    --login-logo-height        : auto;
    --login-logo-width         : 171px;
    --login-logo               : url('/assets/images/powered_by.png');
    --login-brand-logo         : url('/assets/images/clients/mazda-us/login_logo.png');
    --login-brand-logo-width   : 120px;
    --login-brand-logo-height  : 94px;

    --sidebar-width            : 220px;
    --mobile-sidebar-width     : 220px;

    --sidebar-bg               : #282b2e;
    --sidebar-bg-darken-3      : #212426;
    --sidebar-bg-darken-5      : #1c1f21;
    --sidebar-bg-darken-10     : #101213;
    --sidebar-bg-lighten-5     : #34383c;

    --combined-bar-green       : #18b52d;
    --combined-bar-blue        : #224197;
    --combined-bar-yellow      : #f9bb11;
    --combined-bar-red         : #c51230;
}